import React from 'react'
import Layout from "components/layout"
import Section from 'components/section';
import { TitleBanner } from 'components/banners/titleBanner'
import BottomNavigation from 'components/BottomNavigation'
import Organigram from 'components/Organisation';
import TeamModeToggle from '../components/PageToggle/TeamToggle';


export default (props) => {
	console.log(props)
	return (<Layout
		context={props.pageContext}
		location={props.location}
		ancestors={props.data.wordpressPage.ancestors}
		articleClassName="bg-purple-bgultralight"
		actionsClassName="bg-purple-bgultralight text-white pt-4"
		actionsWidgets={<TeamModeToggle />}
	>
		<div style={{ margin: 'auto', padding: "2rem 0rem" }}>
			<TitleBanner title={props.data.wordpressPage.title} content={props.data.wordpressPage.content} />
			<div className="container mx-auto" style={{ maxWidth: 1240 }}>
				{props.data.wordpressPage?.acf?.sections?.length > 0 && <Organigram sections={props.data.wordpressPage.acf.sections} />}
			</div>
		</div>

		{props.data.wordpressPage.showPageGroupWidget && props.data.wordpressPage.siblingPages && props.data.wordpressPage.siblingPages.length > 0 &&

			(<Section>
				<BottomNavigation items={props.data.wordpressPage.siblingPages} />
			</Section>
			)
		}
	</Layout>)
}

export const query = graphql`
query OrganisationPageById($id:String!){
	wordpressPage(id:{eq:$id}){
	  title
	  showPageGroupWidget
	  ancestors {
		link
		label
	  }
	  siblingPages {
		title
		excerpt
		path
	  }
        acf {
          sections {
            title
            style {
              color_scheme
              is_full_width
            }
            members {
              organisation_member_card {
                name
picture {

          localFile {
            childImageSharp {
              fixed( width: 85, height:85) {
                ...GatsbyImageSharpFixed
              }
            }
          }
}
                department
                roles
                background
full_width
              }
            }
          }
        }
	}
}
`
